angular.module('webremote.layout').controller('webremote.modules.layout.header.controller',
  function ($scope, $window, httpService, $rootScope, $location, sipService, authenticationService, webSocketService, objectManagerService, $uibModal, toastr, modalService, deviceService, statsService) {
    $scope.isCollapsed = true
    $scope.fullscreen = false
    $scope.authenticationService = authenticationService
    $scope.sipCallManager = sipService.getSipCallManager
    $scope.om = objectManagerService
    $scope.statsServiceManager = statsService.getStats
    $scope.brightnessLevel = 0
    $scope.deviceService = deviceService

    $scope.om.collection('systemConfig').then((v) => {
      $scope.systemConfig = Array.from(v.values())[0]
    })

    $scope.$watchCollection('authenticationService', function (newValue) {
      if ($scope.authenticationService.session !== undefined) {
        $scope.om.object($scope.authenticationService.session.member.user_uuid, 'user').then((user) => {
          if (user !== undefined && user.member.dashboardOnly) {
            // document.body.style.backgroundColor = 'white'
            document.getElementById('main').style.setProperty('margin-top', '31px', 'important')
            document.getElementById('main').style.setProperty('border-top-left-radius', '5px')
            document.getElementById('main').style.setProperty('border-top-right-radius', '5px')
            document.getElementById('main').style.setProperty('padding-top', '10px')
          }
        })
      }
    })

    $scope.calcTextColor = function () {
      function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null
      }

      const systemConf = $scope.om.objectSync('d7da1bf7-284d-4b6b-a3bc-34791a050bc3', 'systemConfig')
      if (systemConf !== undefined) {
        const color = hexToRgb(systemConf.member.webRemote.webColor)
        if (color.r * 0.299 + color.g * 0.587 + color.b * 0.114 > 186) {
          return '#000000'
        }
      } else {
        return '#ffffff'
      }
    }

    $scope.filterErrors = function (item) {
      return item.member.recovering === false && (item.member.event === '' || item.member.event.startsWith('E') || item.member.event.startsWith('C'))
    }

    $scope.filterWarnings = function (item) {
      return item.member.recovering === false && item.member.event.startsWith('W')
    }

    $scope.filterRecovers = function (item) {
      return item.member.recovering === true
    }

    $scope.getAlarmColor = function () {
      const alarms = $scope.om.collectionSync('alarm')
      for (const alarm of alarms) {
        if (alarm.member.recovering === false && (alarm.member.event === '' || alarm.member.event.startsWith('E') || alarm.member.event.startsWith('C'))) {
          return 'alarm-color-danger'
        }
      }
      for (const alarm of alarms) {
        if (alarm.member.recovering === false && alarm.member.event.startsWith('W')) {
          return 'alarm-color-warning'
        }
      }
      return ''
    }

    $scope.toggleView = function () {
      if (!$scope.fullscreen) {
        document.getElementById('main').style.setProperty('margin-top', '31px', 'important')
        document.getElementById('main').style.setProperty('border-top-left-radius', '5px')
        document.getElementById('main').style.setProperty('border-top-right-radius', '5px')
        document.getElementById('main').style.setProperty('padding-top', '10px')
        document.getElementById('main').style.width = '90%'
      } else {
        document.getElementById('main').style.marginTop = '140px'
        document.getElementById('main').style.setProperty('padding-top', '20px')
        document.getElementById('main').style.width = '1170px'
        document.getElementById('main').style.removeProperty('border-top-left-radius')
        document.getElementById('main').style.removeProperty('border-top-right-radius')
      }

      $scope.fullscreen = !$scope.fullscreen
    }

    /////////////////
    // Lock screen //
    /////////////////
    $scope.isLocked = false
    $scope.lockToggleTime = 1000
    $scope.createOverlay = function () {
      // Create overlay-div
      var overlay = document.createElement('div')
      overlay.id = 'overlay'
      overlay.style.display = 'none'
      overlay.style.position = 'fixed'
      overlay.style.top = '0'
      overlay.style.left = '0'
      overlay.style.width = '100%'
      overlay.style.height = '100%'
      overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.3)'
      overlay.style.zIndex = '1101'

      // Add overlay to body
      document.getElementById('navbar').appendChild(overlay)
    }

    $scope.$watch('deviceService.meta.machine', function () {
      if (deviceService.meta.machine !== undefined) {
        if ($scope.deviceService.meta.machine === 'fernreport') {
          $scope.createOverlay()
        }
      }
    })

    $scope.toggleLock = function () {
      if ($scope.isLocked) {
        // Remove overlay
        document.getElementById('overlay').style.display = 'none'
        $scope.isLocked = false
        $scope.lockToggleTime = 1000
      } else {
        // Show overlay
        document.getElementById('overlay').style.display = 'block'
        $scope.isLocked = true
        $scope.lockToggleTime = 2000
      }
    }

    // Event listener for long press (in ms)
    $scope.lockIcon = document.getElementById('lockIcon')

    $scope.lockIcon.addEventListener('mousedown', function () {
      $scope.pressTimer = setTimeout(function () {
        $scope.toggleLock()
      }, $scope.lockToggleTime)
    })

    $scope.lockIcon.addEventListener('mouseup', function () {
      clearTimeout($scope.pressTimer)
    })

    $scope.lockIcon.addEventListener('touchstart', function () {
      $scope.pressTimer = setTimeout(function () {
        $scope.toggleLock()
      }, $scope.lockToggleTime)
    })

    $scope.lockIcon.addEventListener('touchend', function () {
      clearTimeout($scope.pressTimer)
    })

    $scope.needsReboot = function () {
      $rootScope.needReboot = true
    }

    $scope.gotoNetworkSettings = function () {
      $location.url('settings?module=network')
    }

    webSocketService.on('RebootRequired', $scope.needsReboot)

    $scope.rebootSystem = function () {
      const modalConfig = {
        title: 'Confirm',
        text: 'Are you sure to reboot this device? Active audio transmissions are interrupted during this process.'
      }

      modalService.confirmModal(modalConfig, $scope, function (response) {
        if (response) {
          $scope.modalInstance = $uibModal.open({
            templateUrl: 'modules/layout/rebootModal/template.html',
            controller: 'webremote.modules.layout.rebootModal.controller',
            size: 'md',
            scope: $scope,
            backdrop: 'static',
            keyboard: false
          })
        }
      })
    }

    $scope.changePasswordModal = function () {
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/layout/changePassword/template.html',
        controller: 'webremote.modules.layout.changePassword.controller',
        size: 'md',
        scope: $scope,
        backdrop: 'static',
        keyboard: false
      })
    }

    $scope.wifiModal = function () {
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/layout/wifi/template.html',
        controller: 'webremote.modules.layout.wifi.controller',
        size: 'md',
        scope: $scope,
        backdrop: 'static',
        keyboard: false
      })
    }

    $scope.om.collection('netPhy').then(netPhys => {
      $scope.hasWifi = false
      for (const netPhy of Array.from(netPhys.values())) {
        if (Object.prototype.hasOwnProperty.call(netPhy.member.type, 'netWifi')) {
          $scope.hasWifi = true
        }
      }
    })
  })
